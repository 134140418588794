<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="photo-list-view" class="photoContainer">
    
    <div class="flex flex-wrap items-center flex-grow" style="min-height: 60px;">
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <div style="float: left; margin-right: 30px"> 
        </div>
        <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
        <v-select style="width: 100px;" placeholder="기자" label="nickname" :reduce="nickname => nickname.idx" v-model="selectedWriter"
          :options="writer"/>
        <div style="padding-top: 0;">
          <vs-input style="width: 100px;" class="inputx" placeholder="포토 검색" v-model="searchText" @keyup.enter="searchPhotoList(searchVar)"/>
        </div>
        <div><vs-button color="primary" type="border" class="p-2 cursor-pointer" @click="searchPhotoList(searchVar)">검색</vs-button></div>
        <!-- ITEMS PER PAGE -->
        <!-- <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfPhoto >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfPhoto }} of {{ numOfPhoto }}</span>
            
        <vs-dropdown>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=30">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=50">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
            </div>
        </div> -->
      <!-- </div> -->
    </div>
    <div class="flex flex-wrap flex-grow">
      <div class="photo-list" :data="photo" :key="index" v-for="(photo, index) in users">
        <img :src="imagePath(photo.mimg)" style="max-width: 250px; height: 150px; cursor:pointer;" @click="photoView(imagePath(photo.mimg)) "/>
        <!-- <ckeditor class="photo-subject" :editor="editor" v-model="photo.subject" :config="editorConfig"></ckeditor> -->
        <vs-textarea v-model="photo.subject" rows="2" />
        <!-- <textarea v-model="photo.subject" name="subject" style="width:244px;color:#000f75;font-size:12px;font-weight:bold" rows="2" /> -->
        등록일: {{photo.reg_date}}
        <!-- <ckeditor class="photo-contents" :editor="editor" v-model="photo.contents" :config="editorConfig"></ckeditor> -->
        <vs-textarea v-model="photo.contents" rows="5" />
        <!-- <textarea v-model="photo.contents" name="contents" style="width:244px;font-size:12px;" rows="5" /> -->
        <vs-button v-if="photo.newsidx > 0" style="padding: 0.5rem; margin: 0px 2px;" size="small" color="warning" @click="editNews(photo.newsidx)">기사수정</vs-button>
        <!-- <vs-checkbox v-model="editPhotoCheck" :vs-value="photo" style="align: center"/> -->
      </div>
    </div>
    <div style="text-align: center;">
      <feather-icon icon="ChevronLeftIcon" style="margin-right: 10px;" @click="pagenation('back')"/>
      <span style="font-size: 20px; vertical-align: top; line-height: 25px; margin-right: 10px;">{{currentx}}</span>
      <feather-icon icon="ChevronRightIcon" style="margin-right: 10px;" @click="pagenation('next')"/>
    </div>


    <!-- Start: 포토 팝업 -->
    <div class="photo-fullsize">
      <vs-popup ref="popup-photo" classContent="popup-photo" title="" :active.sync="photoModalActive">
        <img :src="photoModalImg" style="max-width:600px"/>
      </vs-popup>
    </div>
    <!-- End: 포토 팝업 -->


  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {
  },
  data() {
    return {
      // editor: classicEditor,
      editorConfig: {
        toolbar: {
            items: [],
        }
      },
      selectedIndex: 0,
      selectedId: 0,
      users: [],//[{contents: '', subject: '', writer: '', mimg: ''}],
      currentx: 1,
      numOfPhoto: 0,
      numOfPages: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 15,
        state: null,
        usedOption: 'article',
        writer: null,
        searchText: '',
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      editPhotoCheck: [],
      photoModalActive: false,
      photoModalImg: '',
      itemsPerPage: 15,
      state: null,
      selectedWriter: null,
      usedOption: 'article',
      searchText: '',
      usedOptionList: [
        {code: 'article', name: '기사사진'},
      ],
      dateRange: { // used for v-model prop
          startDate: moment().subtract(1, 'month'),
          endDate: moment().add(7, 'day'),
      },
      dateRange2: [new Date(moment().subtract(1, 'month')), new Date()],
      writer: [],
      states: [],
      categories1: [],
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
    }
  },
  beforeRouteLeave (to, from, next) {
    this.photoModalActive = false;
    setTimeout(() => { next(); }, 100);
},
  watch: {
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      this.searchVar.startRow = (this.currentx-1) * this.searchVar.itemsPerPage;
      window.scrollTo(0,0);
      this.getPhotoList(this.searchVar);
    },
    itemsPerPage: function() {
      this.searchVar.itemsPerPage = this.itemsPerPage;
      this.searchVar.startRow = 0;
      window.scrollTo(0,0);
      this.getPhotoList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    pagenation(data){
      if(data == 'back'){
        if(this.currentx > 1){ this.currentx -= 1 }
      }
      else if(data == 'next'){
        this.currentx += 1;
      }
    },
    imagePath(img){
      const rootPhoto="//cdnimage.ebn.co.kr/photo/";
      const rootNews="//cdnimage.ebn.co.kr/news/";
      let imagePath = '';
      if(img.includes('news_')) imagePath = rootNews + img;
      else imagePath = rootPhoto + img;
      // console.log(imagePath);
      return imagePath;
    },
    photoView(imageSrc){
      console.log('in photoView')
      this.photoModalImg = imageSrc;
      this.photoModalActive = true;
    },
    sendToPhotoBank(data) {
      var thisIns = this

      thisIns.$vs.dialog({
        type: 'confirm',
        color: '#6593F5',
        title: `포토 뱅크 이미지 전송`,
        text: `전송하시겠습니까?`,
        accept: function() {

          thisIns.$vs.loading({
            scale: 1.5
          });


          var photoTitles = ``

          for(var idx in data) {

            photoTitles += `${data[idx].subject}<br />`
          }

          var photos = []

          for(idx in data) {

            photos[idx] = {}
            photos[idx].path = data[idx].mimg
            photos[idx].name = data[idx].pno
            photos[idx].extName = data[idx].mimg.substring(data[idx].mimg.lastIndexOf(".")+1, data[idx].mimg.length)
          }
          

          thisIns.$http.post('/api/photo/sendPhotoBank', {photos: photos})
          .then(function (response) {

            console.log('response:', response)

            thisIns.$vs.loading.close();

            thisIns.editPhotoCheck = []

            thisIns.$vs.notify({
              title:'포토뱅크 - 전송 완료',
              text: photoTitles,
              color:'success',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
          })
          .catch(function (error) {
              thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
              thisIns.$vs.loading.close();  
          });
        }
      })
    },
    writeNews(data){
      var photoId = '';
      for(var i in data){
        photoId += data[i].pno + '|';
      }
      photoId = photoId.substring(0, photoId.length -1);
      console.log("write: ", photoId);
      this.$router.push({path: '/writeArticles', query: { pno: photoId, fromPhotoList: true}});
    },
    editNews(newsId){
      console.log("edit: ", newsId);
      this.$router.push({path: '/writeArticles', query: { id: newsId}});
    },
    addPhoto(){
      this.$router.push({path: '/addPhoto'});
    },
    editPhoto(data){
      console.log("edit: ", data);
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/photo/editPhoto', data)
        .then(function (response) {
          console.log(response);
          thisIns.getPhotoList(thisIns.searchVar);
          thisIns.$vs.notify({
            title:'성공',
            text: '포토 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    deletePhoto(data){
      console.log("delete: ", data);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/photo/deletePhoto', data)
        .then(function (response) {
          console.log(response)
          thisIns.getPhotoList(thisIns.searchVar);
          thisIns.$vs.notify({
            title:'성공',
            text: '포토 삭제 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    searchPhotoList(data){
      data.startRow = 0;
      this.currentx = 1;
      // console.log(this.state)
      data.state = this.state;
      data.usedOption = this.usedOption;
      data.writer = this.selectedWriter;
      data.searchText = this.searchText;
      data.startDate = this.dateRange2[0];
      data.endDate = this.dateRange2[1];
      this.getPhotoList(data);
    },
    getPhotoList(data) {
      this.editPhotoCheck = []
      // console.log(data.state, data.category1, data.writer)
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getNewsList/photo', data)
        .then(function (response) {
          console.log(response.data)
          // if(response.data[0][0].photoCount){
          //   thisIns.numOfPhoto = response.data[0][0].photoCount;
          //   thisIns.numOfPages = Math.ceil(response.data[0][0].photoCount / thisIns.searchVar.itemsPerPage);
          //   thisIns.users = response.data[1];
          // }else{
          //   thisIns.numOfPhoto = 0;
          //   thisIns.numOfPages = 0;
          //   thisIns.users = [];//[{contents: '', subject: '', writer: '', mimg: ''}];
          // }
          thisIns.users = response.data;
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.states = this.$store.state.states;
    this.writer = this.$store.state.writer;
    // console.log(this.$store.state.portals);
    this.getPhotoList(this.searchVar);
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/photoList.scss";
</style>
